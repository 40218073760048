import React from 'react';
import {
  faArrowDown,
  faArrowUp,
  faFrown,
  faMeh,
  faSmile,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

type IPercentProps = {
  num: number;
  disabledIcon?: boolean;
};

const getPercentColor = (num: number) => {
  if (!isFinite(num)) {
    return 'bg-[#E5E5E5] text-[#616161] px-[8px]';
  }
  if (num > 0) {
    return 'bg-[#CDF2E8] text-[#04BE8C]';
  }
  if (num < 0) {
    return 'bg-[#FEEAEC] text-[#F72C40]';
  }

  return 'bg-[#E5E5E5] text-[#616161] px-[8px]';
};

export const Percent = ({ num, disabledIcon }: IPercentProps) => {
  const m = twMerge(
    'px-[8px] w-min h-[22px] rounded-[6px] py-[4px] flex justify-center items-center gap-[4px] text-[12px]',
    getPercentColor(num),
  );

  const _renderIcon = (num: number) => {
    if (!isFinite(num)) {
      return null;
    }
    if (num > 0) {
      return <FontAwesomeIcon icon={faArrowUp} className="text-[#04BE8C]" />;
    }
    if (num < 0) {
      return <FontAwesomeIcon icon={faArrowDown} className="text-[#F72C40]" />;
    }
    return null;
  };

  const _renderNum = (num: number) => {
    if (!isFinite(num)) {
      return <span>N/A</span>;
    }
    return <span>{num.toString().replace('-', '')}%</span>;
  };

  return (
    <div className={m}>
      {disabledIcon ? null : _renderIcon(num)}
      {_renderNum(num)}
    </div>
  );
};

const getPercentSentimentColor = (num: number) => {
  if (!isFinite(num)) {
    return 'bg-[#E5E5E5] text-[#616161] px-[8px]';
  }
  if (num < 50) {
    return 'bg-[#CDF2E8] text-[#04BE8C]';
  }
  if (num > 31 && num < 50) {
    return 'bg-[#FFF8E5] text-[#FFB400]';
  }
  // num > 31
  return 'bg-[#FEEAEC] text-[#F72C40]';
};

export const SentimentPercent = ({ num }: IPercentProps) => {
  const mergeClass = twMerge(
    'px-[4px] w-min h-[22px] rounded-[6px] py-[2px] flex justify-center items-center gap-[2px] text-[12px]',
    getPercentSentimentColor(num),
  );
  const _renderIcon = (num: number) => {
    if (!isFinite(num)) {
      return {
        faceIcon: null,
        arrowIcon: null,
      };
    }
    if (num < 50) {
      return {
        faceIcon: <FontAwesomeIcon icon={faSmile} className="text-[#04BE8C]" />,
        arrowIcon: <FontAwesomeIcon icon={faArrowUp} className="text-[#04BE8C]" />,
      };
    } else if (num > 31) {
      return {
        faceIcon: <FontAwesomeIcon icon={faMeh} className="text-[#FFB400]" />,
        arrowIcon: null,
      };
    } else {
      return {
        faceIcon: <FontAwesomeIcon icon={faFrown} className="text-[#F72C40]" />,
        arrowIcon: <FontAwesomeIcon icon={faArrowDown} className="text-[#F72C40]" />,
      };
    }
  };

  const _renderNum = (num: number) => {
    if (!isFinite(num)) {
      return <span>N/A</span>;
    }
    return <span>{num.toString().replace('-', '')}%</span>;
  };

  const { faceIcon, arrowIcon } = _renderIcon(num);

  return (
    <div className="flex gap-x-[8px] items-center">
      {faceIcon}
      <div className={mergeClass}>
        {arrowIcon}
        {_renderNum(num)}
      </div>
    </div>
  );
};
