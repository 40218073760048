import React from 'react';
import { SSO as SSOComponent } from '@components/AuthComponent/SSO';

export const SSO = () => {
  return (
    <>
      <SSOComponent />
    </>
  );
};

export default SSO;
