import { DEFAULT_MAX_TAKE } from '@configs';
import {
  autoSearchMachine,
  generateOptionAndCursorIdV2,
  IGenerateOptionAndCursorIdData,
} from '@machine';
import { useMachine } from '@xstate/react';
import { cloneDeep } from 'lodash';
import { SyntheticEvent, useCallback, useState } from 'react';

export type IFetchFnParams = {
  keyword?: string;
  take: number;
  cursorId?: string;
};

type IDropdowParams<T extends IGenerateOptionAndCursorIdData> = {
  id: string;
  maxTake?: number;
  fetchOnInit?: boolean;
  fetchFn: (params: IFetchFnParams) => Promise<T[]>;
};

export const useDropdown = <T extends IGenerateOptionAndCursorIdData>({
  id,
  maxTake = DEFAULT_MAX_TAKE,
  fetchOnInit = true,
  fetchFn,
}: IDropdowParams<T>) => {
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<T[]>([]);
  const dropdownMachine = useCallback(() => {
    return autoSearchMachine<T>({
      id,
      fetchOnInit,
    });
  }, []);
  const [current, send] = useMachine(dropdownMachine, {
    devTools: import.meta.env.DEV,
    services: {
      fetchData: async (context, _) => {
        const data = await fetchFn({
          keyword: context.keyword || undefined,
          take: maxTake,
          cursorId: context.cursorId || undefined,
        });

        const result = generateOptionAndCursorIdV2(data, maxTake);

        return result;
      },
      loadMore: async (context, _) => {
        if (!context.cursorId) return [];
        // still has more data
        const data = await fetchFn({
          keyword: context.keyword || undefined,
          take: maxTake,
          cursorId: context.cursorId || undefined,
        });

        const result = generateOptionAndCursorIdV2(data, maxTake);

        return result;
      },
    },
  });
  return {
    selected,
    keyword,
    machineKeyword: current.context.keyword,
    data: current.context.data,
    current,
    onScroll: (e: SyntheticEvent<HTMLDivElement>) => {
      const { currentTarget } = e;
      // is scroll bottom
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight ===
          currentTarget.scrollHeight &&
        !current.matches('loadingMore')
      ) {
        send('LOAD_MORE');
      }
    },
    onSelect: (item: T) => {
      setSelected((prev) => {
        const newItems = cloneDeep(prev);
        const index = newItems.findIndex((i) => i.id === item.id);
        if (index === -1) {
          return [...newItems, item];
        }
        newItems.splice(index, 1);
        // remove
        return newItems;
      });
    },
    onClearSelect: () => {
      setSelected([]);
    },
    onUpdateSearchKeyword: (keyword: string) => {
      setKeyword(keyword);
    },
    onUpdateMachineSearchKeyword: (keyword: string) => {
      send('UPDATE_KEYWORD', { keyword });
    },
    onClearParams: () => {
      send('CLEAR_PARAMS');
    },
    onRefetch: () => {
      send('FETCH');
    },
  };
};
