import React from 'react';

import { Form } from 'antd';
import { ActionWrap, Container, ForgotPassword, Logo, AppVersion } from './style';

import {
  FormItem,
  TextInput,
  PasswordInput,
  PrimaryButton,
  LoadingIcon,
  DangerToast,
  TertiaryButton,
} from '@amityco/diana-bot';
import { Font16G3W500, ToastWrap } from '@components/UtilitiesComponent';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { PATH } from '@configs';

export type ILoginComponent = {
  errorMessage: string;
  loading: boolean;
  appVersion?: string;
  onForgotPassword: () => void;
  onLogin: (username: string, password: string) => void;
};

export const Login = ({
  errorMessage,
  loading,
  appVersion,
  onForgotPassword,
  onLogin,
}: ILoginComponent) => {
  return (
    <Container>
      <Logo />

      {errorMessage && (
        <ToastWrap>
          <DangerToast align="flex-start">{errorMessage}</DangerToast>
        </ToastWrap>
      )}
      <Form
        autoComplete="off"
        layout="vertical"
        onFinish={({ username, password }) => onLogin(username, password)}
      >
        <FormItem
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your username!',
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid username format',
            },
          ]}
        >
          <TextInput placeholder="Username" size="large" />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your password!',
            },
          ]}
        >
          <PasswordInput
            placeholder="Password"
            size="large"
            autoComplete="new-password"
          />
        </FormItem>
        <ActionWrap>
          <PrimaryButton
            size="large"
            htmlType="submit"
            disabled={loading}
            data-testid="test-login-btn"
          >
            {loading && <LoadingIcon icon={faSyncAlt} data-testid="test-loading-icon" />}
            Log in
          </PrimaryButton>
          <ForgotPassword>
            <a onClick={onForgotPassword}>Forgot password?</a>
          </ForgotPassword>
        </ActionWrap>
      </Form>
      <div className="flex items-center flex-col gap-y-4">
        <Font16G3W500>Or continue with :</Font16G3W500>
        <div className="w-full">
          <Link to={PATH.SSO}>
            <TertiaryButton
              className="w-full"
              icon={<FontAwesomeIcon icon={faKey} />}
              size="large"
            >
              Log in with SSO
            </TertiaryButton>
          </Link>
        </div>
        <AppVersion>{appVersion}</AppVersion>
      </div>
    </Container>
  );
};
