import {
  ECampaignSubProduct,
  EChatbotSubProduct,
  EInsightSubProduct,
  EProduct,
} from '@configs';
import { IRole, IChatbotSubProduct, ICampaignSubProduct } from '@types';
import { isEmpty, isArray } from 'lodash';

export const hasAccess = (
  role: IRole,
  product: keyof IRole,
  subProduct?: IChatbotSubProduct | ICampaignSubProduct | EInsightSubProduct,
) => {
  const mainProduct = role && role[product];
  if (subProduct) {
    const isProductKey = isArray(mainProduct);
    if (isProductKey) {
      return false;
    }
    return !isEmpty(role && mainProduct && mainProduct[subProduct]);
  }
  return !isEmpty(role && mainProduct);
};

export const accessControl = (role?: IRole) => {
  return {
    canAccessCim: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CIM);
    },
    canAccessInsight: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.INSIGHT);
    },
    canAccessInsightJourney: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.INSIGHT, EInsightSubProduct.JOURNEY);
    },
    canAccessBotSetting: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CIM);
    },
    canAccessIntent: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.INTENTS);
    },
    canAccessEntities: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.ENTITIES);
    },
    canAccessMapper: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.MAPPER);
    },
    canAccessTemplate: () => {
      if (!role) return false;
      return (
        hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.TEMPLATE) ||
        hasAccess(role, EProduct.CAMPAIGN, ECampaignSubProduct.TEMPLATE)
      );
    },
    canAccessChatlogic: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.CHATLOGIC);
    },
    canAccessVariable: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CHATBOT);
    },
    canAccessAudience: () => {
      if (!role) return false;
      return (
        hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.AUDIENCE) ||
        hasAccess(role, EProduct.CAMPAIGN, ECampaignSubProduct.AUDIENCE)
      );
    },
    canAccessRichmenu: () => {
      if (!role) return false;
      return (
        hasAccess(role, EProduct.CHATBOT, EChatbotSubProduct.RICHMENU) ||
        hasAccess(role, EProduct.CAMPAIGN, ECampaignSubProduct.RICHMENU)
      );
    },

    canAccessCampaign: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CAMPAIGN);
    },
    canAccessSegment: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CAMPAIGN, ECampaignSubProduct.SEGMENT);
    },
    canAccessChatAds: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.CAMPAIGN, ECampaignSubProduct.CHATADS);
    },
    canAccessLiveChat: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.LIVECHAT);
    },
    canAccessGenAi: () => {
      if (!role) return false;
      return hasAccess(role, EProduct.GENAI);
    },
  };
};
