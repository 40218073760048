import { useMemo, useState } from 'react';
import { useAuth, useMember } from '@providers';
import { fetchNetworkSettingByNid } from '@api/bot_setting';
import { useQuery } from '@tanstack/react-query';
import {
  ENETWORK_SETTING_SOCKET_EVENT,
  INetworkSettingBE,
  INetworkSettingSocketEvent,
} from '@types';
import { useSocket } from '@hooks';
import { CIM_API_ENDPOINT } from '@api';
import { isEmpty } from 'lodash';

export const useNetworkSetting = () => {
  const { member } = useMember();
  const { cognitoUser } = useAuth();
  const [networkSetting, setNetworkSetting] = useState<INetworkSettingBE | undefined>(
    undefined,
  );
  const token = useMemo(() => {
    // posible value is '' and token string
    return cognitoUser?.getSignInUserSession()?.getIdToken().getJwtToken();
  }, [cognitoUser]);

  const { isConnected } = useSocket<string>({
    url: CIM_API_ENDPOINT as string,
    token: token ? token : '',
    onNewMessage: (message: string) => {
      try {
        const data: INetworkSettingSocketEvent = JSON.parse(message);
        switch (data.event) {
          case ENETWORK_SETTING_SOCKET_EVENT.UPDATED:
            setNetworkSetting(data.json);
            break;
        }
      } catch (error) {
        console.error('error parsing network setting', error);
      }
    },
  });
  const result = useQuery(
    ['networkSetting', member?.attributes['custom:networkId']],
    () =>
      member
        ? fetchNetworkSettingByNid(member?.attributes['custom:networkId'])
        : Promise.resolve({} as INetworkSettingBE),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setNetworkSetting(data);
      },
    },
  );
  return {
    ...result,
    networkSetting,
    isSSOEnabled:
      (!isEmpty(networkSetting?.config?.ssoSettings) &&
        networkSetting?.config?.ssoSettings?.every((ssoSetting) => ssoSetting.enabled)) ||
      false,
    socket: {
      isConnected,
    },
  };
};
