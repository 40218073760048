/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState } from 'react';
import { Container } from './style';
import { Font16G1W600, Font16G3W400, Font24G1W700 } from '@components/UtilitiesComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { Form, Tooltip } from 'antd';
import {
  DangerToast,
  FormItem,
  GhostButton,
  LoadingIcon,
  PrimaryButton,
  TextInput,
  ToastWrap,
} from '@amityco/diana-bot';
import { Link } from 'react-router-dom';
import { useTranslation } from '@hooks/useTranslation';
import { useMutation } from '@tanstack/react-query';
import { fetchSSO } from '@api';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';

export const SSO = () => {
  const t = useTranslation();
  const [domain, setDomain] = useState('');
  const [form] = Form.useForm();
  const organizeMute = useMutation({
    mutationFn: async (oid: string) => {
      try {
        const { data } = await fetchSSO(oid);
        await Auth.federatedSignIn({
          customProvider: data.providerName,
        });
        return data;
      } catch (error) {
        console.error('sso error', error);
        throw error;
      }
    },
  });
  const onSubmit = async (values: { oid: string }) => {
    organizeMute.mutate(values.oid);
  };
  const errorUi = useMemo(() => {
    if (organizeMute.isError) {
      if ((organizeMute.error as AxiosError).response?.status === 404) {
        return (
          <ToastWrap>
            <DangerToast align="flex-start">{t('sso.page.error.notFound')}</DangerToast>
          </ToastWrap>
        );
      }
      return (
        <ToastWrap>
          <DangerToast align="flex-start">{t('general.error.title')}</DangerToast>
        </ToastWrap>
      );
    }

    return null;
  }, [organizeMute.isError]);

  return (
    <Container>
      <Font24G1W700>{t('sso.page.title')}</Font24G1W700>
      <Font16G3W400>{t('sso.page.description1')}</Font16G3W400>
      <Font16G3W400>{t('sso.page.description2')} </Font16G3W400>
      <div className="flex gap-x-2 items-center">
        <Font16G1W600>{t('sso.page.organizationDomain')}</Font16G1W600>
        <Tooltip
          placement="right"
          title={
            <>
              <ul>
                <li>{t('sso.page.tooltip.rule1')}</li>
                <li>{t('sso.page.tooltip.rule2')}</li>
                <li>{t('sso.page.tooltip.rule3')}</li>
                <li>{t('sso.page.tooltip.rule4')}</li>
                <li>{t('sso.page.tooltip.rule5')}</li>
              </ul>
            </>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} className="text-[#BDBDBD]" />
        </Tooltip>
      </div>
      {errorUi}
      <Form form={form} onFinish={(values) => onSubmit(values)}>
        <FormItem
          name="oid"
          rules={[
            // max 32 characters
            {
              max: 32,
              message: t('sso.page.error.maxLength'),
            },
            // can be alphanumeric and hyphen
            {
              pattern: /^[a-zA-Z0-9-]+$/,
              message: t('sso.page.error.invalid'),
            },
          ]}
        >
          <TextInput
            size="large"
            placeholder={t('sso.page.organizationDomain.placeholder')}
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
        </FormItem>
        <FormItem shouldUpdate>
          {() => {
            return (
              <PrimaryButton
                htmlType="submit"
                className="w-full"
                size="large"
                disabled={
                  !domain ||
                  form.getFieldsError().some(({ errors }) => errors.length) ||
                  organizeMute.isLoading
                }
              >
                {organizeMute.isLoading && (
                  <LoadingIcon icon={faSyncAlt} data-testid="test-loading-icon" />
                )}
                {t('sso.page.signInButton')}
              </PrimaryButton>
            );
          }}
        </FormItem>
      </Form>
      <Link to={-1 as any} className="w-fit -mt-2">
        <GhostButton size="large" icon={<FontAwesomeIcon icon={faArrowLeft} />}>
          {t('sso.page.backButton')}
        </GhostButton>
      </Link>
    </Container>
  );
};
