import { CIM_API_ENDPOINT, client } from '@api';
import { IChannelApiResponse, IFBChannelInfoApi, IFetchChannelRequest } from '@types';
import axios from 'axios';

export type IConnectChannelPayload<T> = {
  id?: string;
  name?: string;
  channelId?: string;
  config?: T;
  activate?: boolean;
};

export type IConnectFacebookChannelPayload = IConnectChannelPayload<{
  pageToken?: string;
  channelAccessToken?: string;
  channelSecret?: string;
}>;

export type IConnectLineChannelPayload = IConnectChannelPayload<{
  token?: string;
  secret?: string;
  metadata?: unknown;
}>;

export type IConnectAmityChatChannelPayload = IConnectChannelPayload<{
  adminAccessToken?: string;
  secureMode: boolean;
  serverKeys: string[];
  tokenExpireMinutes: number;
}>;

export type IConnectCustomChannelPayload = IConnectChannelPayload<{
  webhookUrl?: string;
  baseUrl?: string;
}>;

type IUpdateWebchatChannelParams = {
  secureMode: boolean;
  serverKeys: string[] | null;
  tokenExpireMinutes: number | null;
};

type IConnectAllChannelPayload =
  | IConnectFacebookChannelPayload
  | IConnectLineChannelPayload
  | IConnectAmityChatChannelPayload
  | IConnectCustomChannelPayload;

// TODO: change this when filter already
export const getAllChannel = async (params: IFetchChannelRequest) => {
  const { data }: { data: IChannelApiResponse[] } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/channels`,
    {
      params: {
        take: params?.take,
        cursorId: params?.cursorId,
        keyword: params?.keyword,
        skip: params?.skip,
        type: params?.type,
      },
    },
  );
  return data;
};

export const createChannel = (payload: IConnectAllChannelPayload) => {
  return client.post(`${CIM_API_ENDPOINT}/api/v1/channels`, payload);
};

export const updateChannel = (payload: IConnectAllChannelPayload) => {
  return client.put(`${CIM_API_ENDPOINT}/api/v1/channels/${payload.id}`, payload);
};

export const updateWebchatChannel = (
  id: string,
  payload: IUpdateWebchatChannelParams,
) => {
  return client.put(`${CIM_API_ENDPOINT}/api/v1/channels/${id}/config`, {
    config: payload,
  });
};

export const deleteChannel = (id: string) => {
  return client.delete(`${CIM_API_ENDPOINT}/api/v1/channels/${id}`);
};

export const getFbPages = async (token: string, userId: string) => {
  const { data }: { data: { data: IFBChannelInfoApi[] } } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/channels/facebook/pages?token=${token}&userId=${userId}`,
  );
  return data;
};

// for custom channel only
export const generateKey = (channelId: string) => {
  return client.post(`${CIM_API_ENDPOINT}/api/v1/channels/refresh-api-key`, {
    id: channelId,
  });
};

export const subscribeFbChannel = ({
  channelId,
  pageToken,
}: {
  channelId: string;
  pageToken: string;
}) => {
  return axios.post(
    `https://graph.facebook.com/v6.0/${channelId}/subscribed_apps?access_token=${pageToken}&subscribed_fields=messages,messaging_postbacks`,
  );
};
