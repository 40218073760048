import { IActionList } from '@components/ButtonAction/ButtonActionProvider';
import { MESSAGE_TYPE } from '@configs';
import { FlexContainer } from '@line/bot-sdk';
import {
  IMessageAction,
  ITextMessageFE,
  IImageMessageFE,
  ICarouselMessageFE,
  IImageCarouselFE,
  IButtonsMessageFE,
  IImageMapMessageFE,
  IFlexMessageFE,
  ITemplateFE,
  EMessageActionType,
  IMessageFE,
  IHtmlMessageFE,
} from '@types';
import uniqid from 'uniqid';

export const MAX_OPEN_TICKET_TAG = 20;
export const MAX_TEMPLATE_NAME = 100;
export const DEFAULT_PLATFORM = 'multi';
export const MAX_CAROUSEL_DESCRIPTION_LENGTH = 1000;
export const MAX_CAROUSEL_TITLE_LENGTH = 40;
export const MAX_CAROUSEL_DESCRIPTION_LENGTH_IF_TITLE_OR_IMAGE = 60;
export const TEMPLATE_BUTTON_ACTION_LABEL_TITLE = 'Enter your button name';
export const TEMPLATE_BUTTON_ACTION_PLACEHOLDER = 'Enter your label';
export const TEMPLATE_BUTTON_ACTION_LABEL_PLACEHOLDER = 'Enter your button text';

export const TEMPLATE_IMAGE_CAROUSEL_MAX_LABEL_NAME_LENGTH = 12;

export const TEMPLATE_CAROUSEL_MAX_BUTTON = 10;
export const TEMPLATE_OPTION_MAX_BUTTON = 10;
export const TEMPLATE_OPTIONS_MAX_BUTTONS = {
  LINE: 4,
  FACEBOOK: 3,
  WEBCHAT: 10,
};

export const TEMPLATE_CAROUSEL_MAX_BUTTONS = {
  LINE: 3,
  FACEBOOK: 3,
  WEBCHAT: 10,
};
// Webchat
export const WEBCHAT_ACTION_LABEL_MAX_LENGTH = 200;
export const WEBCHAT_MAX_CAROUSEL_TITLE_LENGTH = 200;
export const WEBCHAT_MAX_TEXT_BUTTON_LENGTH = 1000;
export const WEBCHAT_MAX_TEXT_LENGTH = 10000;
// Facebook
export const FACEBOOK_MAX_CAROUSEL_TITLE_LENGTH = 40;
export const FACEBOOK_MAX_TEXT_LENGTH = 2000;
export const FACEBOOK_MAX_OPTION_BUTTONS = 3;
export const FACEBOOK_COLUMN_MAX_TITLE_LENGTH = 80;
export const FACEBOOK_MAX_TEXT_BUTTON_LENGTH = 160;
export const FACEBOOK_CAROUSEL_COLUMN_ACTION_MAX_LABEL_LENGTH = 20;
export const VALIDATE_BORDER = `border: 2px solid #F72C40 !important;`;
export const VALIDATE_TEXT_COLOR = `#F72C40;`;
export const FACEBOOK_OPTIONS_ACTION_MAX_LABEL_LENGTH = 20;
// Line
export const LINE_MAX_CAROUSEL_TITLE_LENGTH = 40;
export const LINE_MAX_CAROUSEL_TEXT_LENGTH = 120;
export const LINE_MAX_CAROUSEL_TEXT_LENGTH_WITH_TEXT_OR_IMAGE = 60;
export const LINE_MAX_TEXT_LENGTH = 5000;
export const LINE_MAX_TEXT_BUTTON_LENGTH = 160;
export const LINE_CAROUSEL_COLUMN_ACTION_MAX_LABEL_LENGTH = 20;
export const LINE_OPTIONS_ACTION_MAX_LABEL_LENGTH = 20;
// Other
export const MAX_CAROUSEL_COLUMN_LENGTH = 10;
export const TEMPLATE_API_MAX_TAKE = 10;
export const TEMPLATE_MESSAGE_ACTION_MAX_LABEL_LENGTH = 300;
export const TEMPLATE_ACTION_MAX_LABEL_LENGTH = 20;
export const TEMPLATE_EDITOR_MAX_MESSAGE_LENGTH = 5;
export const TEMPLATE_CAROUSEL_MAX_TEXT_LENGTH = 60;
export const IMAGE_MAP_MAX_REGIONS = 50;
export const MAX_VIDEO_FILE_SIZE = 25; //MB
export const MAX_IMAGE_FILE_SIZE = 1; //MB
export const IMAGE_MAP_MIN_WIDTH = 372; //px
export const IMAGE_MAP_MIN_HEIGHT = 372; //px
export const IMAGE_MAP_DIMISION = {
  WIDTH: 1040, //px
  HEIGHT: 1040, //px
};

export const TEMPLATE_NAME_MIN_LENGTH = 3;

export const TEMPLATE_VALIDATE_MESSAGE = {
  NAME_REQUIRED: 'Name is required',
  NAME_MIN_LENGTH: 'Template name character limit',
  MESSAGE_REQUIRED: 'Please check completeness',
  ALTEXT_REQUIRED: 'Alt text is required',
};

export const TEMPLATE_VALIDATE_MESSAGE_DESCRIPTION = {
  NAME_MIN_LENGTH:
    'Please make sure you follow template name conditions 3-100 characters, alphabets (eng only), 0-9,  _ ',
};
export const CAROUSEL_IMAGE_ASPECT_RATIO = {
  RECTANGLE: 'rectangle' as const,
  SQUARE: 'square' as const,
};
export const CAROUSEL_IMAGE_SIZE = {
  COVER: 'cover' as const,
  CONTAIN: 'contain' as const,
};
export const IMAGE_MAP_MODE = {
  EDIT: 'edit' as const,
  PREVIEW: 'preview' as const,
};
export const TEMPLATE_ACTION_LIST: IActionList[] = [
  {
    label: 'Receive as text',
    value: 'message',
  },
  {
    label: 'Send Template',
    value: 'template_action',
  },
  {
    label: 'Go to Flow',
    value: 'flow_action',
  },
  {
    label: 'Open website',
    value: 'uri',
  },
  {
    label: 'Call number',
    value: 'call_action',
  },
  {
    label: 'Open camera',
    value: 'camera',
  },
  {
    label: 'Date time picker',
    value: 'datetimepicker',
  },
  {
    label: 'Location',
    value: 'location',
  },
  {
    label: 'Open ticket',
    value: 'assign_ticket',
  },
];

export const MESSAGE_ACTION_LABEL = 'Button';

export const INITIAL_TEMPLATE = () => {
  return {
    id: uniqid(),
    name: '',
    messages: [] as IMessageFE[],
    platform: DEFAULT_PLATFORM as ITemplateFE['platform'],
    tags: [],
    errorMessage: '',
    quickReply: {
      items: [],
    },
  };
};

export const INITIAL_IMAGE_CAROUSEL_ACTION = (): IMessageAction => {
  return {
    id: uniqid(),
    type: EMessageActionType.Message,
    label: '',
    text: '',
    isPopover: false,
  };
};

export const INITIAL_ACTION = (): IMessageAction => {
  return {
    id: uniqid(),
    type: EMessageActionType.Message,
    label: MESSAGE_ACTION_LABEL,
    text: '',
    isPopover: false,
  };
};

export const TEMPLATE_LIST_TABLE_MOCK = [
  {
    key: '1',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
  {
    key: '1',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
  {
    key: '2',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
  {
    key: '3',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
  {
    key: '4',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
  {
    key: '5',
    templateName: 'Promotion SongkranFes',
    platforms: 32,
    lastModifyName: 'kim',
    lastModifyDate: new Date().toDateString(),
  },
];

export const LINE_MESSAGE = [
  {
    label: 'Text',
    icon: 'text',
    value: 'text',
  },
  {
    label: 'Media',
    icon: 'photo-video',
    value: 'media',
  },
  {
    label: 'Carousel',
    icon: 'layer-group',
    value: 'carousel',
  },
  {
    label: 'Image Carousel',
    icon: 'image',
    value: 'image_carousel',
  },
  {
    label: 'Option',
    icon: 'list',
    value: 'buttons',
  },
  {
    label: 'Imagemap',
    icon: 'draw-square',
    value: 'imagemap',
  },
  {
    label: 'Flex',
    icon: 'ballot',
    value: 'flex',
  },
  {
    label: 'Html',
    icon: 'code',
    value: 'html',
  },
];

export const FACEBOOK_MESSAGE = [
  {
    label: 'Text',
    icon: 'text',
    value: 'text',
  },
  {
    label: 'Media',
    icon: 'photo-video',
    value: 'media',
  },
  {
    label: 'Carousel',
    icon: 'layer-group',
    value: 'carousel',
  },
  {
    label: 'Option',
    icon: 'list',
    value: 'buttons',
  },
];

export const WEBCHAT_MESSAGE = [
  {
    label: 'Text',
    icon: 'text',
    value: 'text',
  },
  {
    label: 'Media',
    icon: 'photo-video',
    value: 'media',
  },
  {
    label: 'Carousel',
    icon: 'layer-group',
    value: 'carousel',
  },
  {
    label: 'Option',
    icon: 'list',
    value: 'buttons',
  },
];

export const MULTIPLATFORM_MESSAGE = [
  {
    label: 'Text',
    icon: 'text',
    value: 'text',
  },
  {
    label: 'Media',
    icon: 'photo-video',
    value: 'media',
  },
  {
    label: 'Carousel',
    icon: 'layer-group',
    value: 'carousel',
  },
  {
    label: 'Option',
    icon: 'list',
    value: 'buttons',
  },
];

export const DEFAULT_TEXT_MESSAGE = (id = uniqid()): ITextMessageFE => ({
  id,
  text: '',
  type: MESSAGE_TYPE.TEXT,
  errorMessage: '',
  quickReply: {
    items: [],
  },
});

export const DEFAULT_IMAGE_MESSAGE = (id = uniqid()): IImageMessageFE => ({
  id,
  type: MESSAGE_TYPE.IMAGE,
  previewImageUrl: '',
  originalContentUrl: '',
  errorMessage: '',
  quickReply: {
    items: [],
  },
});

export const DEFAULT_CAROUSEL_MESSAGE = (id = uniqid()): ICarouselMessageFE => ({
  id,
  type: MESSAGE_TYPE.CAROUSEL,
  altText: '',
  columns: [
    {
      id: uniqid(),
      actions: [INITIAL_ACTION()],
      index: 0,
      isActive: true,
      title: '',
      text: '',
      errorMessage: '',
    },
  ],
  quickReply: {
    items: [],
  },
});

export const DEFAULT_IMAGE_CAROUSEL_MESSAGE = (id = uniqid()): IImageCarouselFE => ({
  id,
  type: MESSAGE_TYPE.IMAGE_CAROUSEL,
  altText: '',
  columns: [
    {
      id: uniqid(),
      imageUrl: '',
      action: INITIAL_IMAGE_CAROUSEL_ACTION(),
      errorMessage: '',
      index: 0,
      isActive: true,
    },
  ],
  quickReply: {
    items: [],
  },
});

export const DEFAULT_BUTTONS_MESSAGE = (id = uniqid()): IButtonsMessageFE => ({
  id,
  type: MESSAGE_TYPE.OPTIONS,
  altText: '',
  actions: [INITIAL_ACTION()],
  text: '',
  errorMessage: '',
  quickReply: {
    items: [],
  },
});

export const DEFAULT_IMAGEMAP_MESSAGE = (id = uniqid()): IImageMapMessageFE => ({
  id,
  baseSize: {
    width: IMAGE_MAP_DIMISION.WIDTH,
    height: IMAGE_MAP_DIMISION.HEIGHT,
  },
  baseUrl: '',
  altText: '',
  type: MESSAGE_TYPE.IMAGEMAP,
  imgUrl: '',
  imgHeight: IMAGE_MAP_DIMISION.WIDTH,
  imgWidth: IMAGE_MAP_DIMISION.HEIGHT,
  actions: [],
  mode: 'preview',
  quickReply: {
    items: [],
  },
});

export const DEFAULT_FLEX_MESSAGE = (id = uniqid()): IFlexMessageFE => ({
  id,
  type: MESSAGE_TYPE.FLEX,
  contents: {} as FlexContainer,
  altText: '',
  quickReply: {
    items: [],
  },
  errorMessage: '',
});

export const DEFAULT_HTML_MESSAGE = (id = uniqid()): IHtmlMessageFE => ({
  id,
  type: MESSAGE_TYPE.HTML,
  htmlContent: '',
  quickReply: {
    items: [],
  },
  errorMessage: '',
});

export const SAMPLE_FLEX: FlexContainer = {
  type: 'bubble',
  hero: {
    type: 'image',
    url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
    size: 'full',
    aspectRatio: '20:13',
    aspectMode: 'cover',
    action: {
      label: '',
      type: 'uri' as const,
      uri: 'http://linecorp.com/',
    },
  },
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'text',
        text: 'Brown Cafe',
        weight: 'bold',
        size: 'xl',
      },
      {
        type: 'box',
        layout: 'baseline',
        margin: 'md',
        contents: [
          {
            type: 'icon',
            size: 'sm',
            url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
          },
          {
            type: 'icon',
            size: 'sm',
            url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
          },
          {
            type: 'icon',
            size: 'sm',
            url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
          },
          {
            type: 'icon',
            size: 'sm',
            url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png',
          },
          {
            type: 'icon',
            size: 'sm',
            url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png',
          },
          {
            type: 'text',
            text: '4.0',
            size: 'sm',
            color: '#999999',
            margin: 'md',
            flex: 0,
          },
        ],
      },
      {
        type: 'box',
        layout: 'vertical',
        margin: 'lg',
        spacing: 'sm',
        contents: [
          {
            type: 'box',
            layout: 'baseline',
            spacing: 'sm',
            contents: [
              {
                type: 'text',
                text: 'Place',
                color: '#aaaaaa',
                size: 'sm',
                flex: 1,
              },
              {
                type: 'text',
                text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo',
                wrap: true,
                color: '#666666',
                size: 'sm',
                flex: 5,
              },
            ],
          },
          {
            type: 'box',
            layout: 'baseline',
            spacing: 'sm',
            contents: [
              {
                type: 'text',
                text: 'Time',
                color: '#aaaaaa',
                size: 'sm',
                flex: 1,
              },
              {
                type: 'text',
                text: '10:00 - 23:00',
                wrap: true,
                color: '#666666',
                size: 'sm',
                flex: 5,
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    type: 'box',
    layout: 'vertical',
    spacing: 'sm',
    contents: [
      {
        type: 'button',
        style: 'link',
        height: 'sm',
        action: {
          type: 'uri',
          label: 'CALL',
          uri: 'https://linecorp.com',
        },
      },
      {
        type: 'button',
        style: 'link',
        height: 'sm',
        action: {
          type: 'uri',
          label: 'WEBSITE',
          uri: 'https://linecorp.com',
        },
      },
      {
        type: 'box',
        layout: 'vertical',
        contents: [],
        margin: 'sm',
      },
    ],
    flex: 0,
  },
};
