import React, { useCallback } from 'react';
import { DropdownTags, EDropdownSize } from '@components/DropdownTags';
import { useTranslation } from '@hooks/useTranslation';
import { Container, DropdownLabel, SelectedWrap, Wrap } from './style';
import { IChannelApiResponse } from '@types';
import { Font14G1W400 } from '@components/UtilitiesComponent';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropdown } from '@hooks/useDropdown';
import { getAllChannel } from '@api';
import { DEFAULT_MAX_TAKE } from '@configs';
import { DropdownItem } from '@components/DropdownTags/style';
import { Checkbox } from '@amityco/diana-bot';
import { Channel } from '@components/Channel';
import { Row, Col } from 'antd';

export type IDropdownChannelProps = {
  channels: IChannelApiResponse[];
  onSelectChannel: (value: IChannelApiResponse) => void;
  onClearChannel: () => void;
};

export const DropdownChannel = ({
  channels,
  onSelectChannel,
  onClearChannel,
}: IDropdownChannelProps) => {
  const t = useTranslation();
  const { current, onScroll, onUpdateMachineSearchKeyword } =
    useDropdown<IChannelApiResponse>({
      id: 'channel_machine',
      fetchFn: getAllChannel,
      maxTake: DEFAULT_MAX_TAKE,
    });

  const isChecked = useCallback(
    (option: IChannelApiResponse) => {
      const checked = channels.some((tag) => tag.id === option.id);
      return checked;
    },
    [channels],
  );
  return (
    <Container>
      <DropdownTags<IChannelApiResponse, IChannelApiResponse>
        canClear
        placeholder={''} // this props will not use if renderButton is provided
        size={EDropdownSize.MIDDLE}
        maxHeight={150}
        overlayMaxHeight={400}
        buttonWidth={200}
        extraOverlayWidth={100}
        isDisabled={false}
        options={current.context.data}
        onScroll={onScroll}
        keyword={current.context.keyword}
        selectedTag={channels}
        onSelect={(value) => {
          onSelectChannel(value);
        }}
        onUpdateSearchKeyword={onUpdateMachineSearchKeyword}
        onClearSearchKeyword={() => {
          onUpdateMachineSearchKeyword('');
        }}
        onClearSelect={onClearChannel}
        emptyWord={t('dashboard.dropdown.channel.no.result')}
        fullMessage={''}
        onDeleteTag={() => false}
        isLoadingMore={current.matches('loadingMore')}
        renderCustomDropdownContent={(options) => {
          return (
            <>
              {options.map((item) => {
                return (
                  <DropdownItem
                    key={item.id}
                    onClick={() => {
                      //call external function
                      onSelectChannel(item);
                    }}
                  >
                    <Row gutter={[8, 0]} wrap={false}>
                      <Col>
                        <Channel channelType={item.type} />
                      </Col>
                      <Col>
                        <DropdownLabel>{item.name}</DropdownLabel>
                      </Col>
                    </Row>
                    <Checkbox checked={isChecked(item) ? true : false} />
                  </DropdownItem>
                );
              })}
            </>
          );
        }}
        renderButton={(selectedTag) => {
          // non select any tag
          if (selectedTag.length === 0) {
            return (
              <>
                <Font14G1W400>{t('dashboard.dropdown.channel.placeholder')}</Font14G1W400>
                <FontAwesomeIcon icon={faChevronDown} color="rgba(0,0,0,.25)" />
              </>
            );
          }
          // select one tag
          if (selectedTag.length === 1) {
            return (
              <Wrap>
                <SelectedWrap>
                  <Font14G1W400>{selectedTag[0].name}</Font14G1W400>
                </SelectedWrap>
                <FontAwesomeIcon icon={faChevronDown} color="rgba(0,0,0,.25)" />
              </Wrap>
            );
          }
          // select more than one tag
          return (
            <Wrap>
              <SelectedWrap>
                <Font14G1W400>
                  {selectedTag.length}{' '}
                  {t('dashboard.dropdown.channel.selected.count.label')}
                </Font14G1W400>
              </SelectedWrap>
              <FontAwesomeIcon icon={faChevronDown} color="rgba(0,0,0,.25)" />
            </Wrap>
          );
        }}
      />
    </Container>
  );
};
