export const awsExports = {
  aws_project_region: import.meta.env.VITE_COGNITO_REGION,
  authenticationFlowType: import.meta.env.VITE_COGNITO_AUTH_FLOW_TYPE,
  Auth: {
    region: import.meta.env.VITE_COGNITO_AUTH_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_AUTH_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_AUTH_USER_POOL_CLIENT_ID,
    oauth: {
      domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN, // Your Cognito domain
      scope: import.meta.env.VITE_COGNITO_OAUTH_SCOPE?.split(',') ?? [], // Adjust scopes as needed
      redirectSignIn: import.meta.env.VITE_COGNITO_OAUTH_SIGN_IN_REDIRECT_URL,
      redirectSignOut: import.meta.env.VITE_COGNITO_OAUTH_SIGN_OUT_REDIRECT_URL,
      responseType: import.meta.env.VITE_COGNITO_OAUTH_RESPONSE_TYPE, // Authorization code grant
    },
    cookieStorage: {
      domain: import.meta.env.VITE_COGNITO_AUTH_COOKIE_DOMAIN,
      path: import.meta.env.VITE_COGNITO_AUTH_COOKIE_PATH,
      expires:
        typeof import.meta.env.VITE_COGNITO_AUTH_COOKIE_EXPIRES === 'string'
          ? parseFloat(import.meta.env.VITE_COGNITO_AUTH_COOKIE_EXPIRES)
          : 1,
      secure: import.meta.env.VITE_COGNITO_AUTH_COOKIE_SECURE ? true : false,
      sameSite: 'strict',
    },
  },
};
