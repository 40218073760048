import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, LoadingCircle, TagWithRemove } from '@amityco/diana-bot';
import { Dropdown } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import {
  Container,
  DisabledContainer,
  DropdownContainer,
  DropdownItem,
  DropdownItemLabel,
  EmptyWord,
  SearchBox,
  Content,
  PlaceholderText,
  SearchInput,
  ClearButton,
} from './style';
import { isEmpty, uniqueId } from 'lodash';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Font12G4W400 } from '@components/UtilitiesComponent';

export const enum EDropdownSize {
  MIDDLE = 'middle',
  LARGE = 'large',
}

export type IDropdownTagsOption = {
  id: string;
  name: string;
};

export type IDropdownTagsProps<
  OptionType extends IDropdownTagsOption,
  SelectedType extends IDropdownTagsOption,
> = {
  keyword: string;
  selectedTag: SelectedType[];
  isDisabled: boolean;
  options: OptionType[];
  emptyWord: string;
  fullMessage: string;
  overlayMaxHeight?: number;
  buttonWidth?: number;
  placeholder?: string;
  extraOverlayWidth?: number;
  maxHeight?: number;
  size?: EDropdownSize;
  canClear?: boolean;
  tagClassName?: string;
  isLoadingMore?: boolean;
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onSelect: (value: OptionType) => void;
  onUpdateSearchKeyword: (keyword: string) => void;
  onDeleteTag: (value: string) => void;
  onClearSearchKeyword: () => void;
  renderButton?: (selectedTag: SelectedType[]) => React.ReactNode;
  onClearSelect?: () => void;
  renderCustomDropdownContent?: (options: OptionType[]) => React.ReactNode;
};

export const DropdownTags = <
  OptionType extends IDropdownTagsOption,
  SelectedType extends IDropdownTagsOption,
>({
  keyword,
  selectedTag = [],
  options = [],
  isDisabled,
  placeholder,
  emptyWord,
  fullMessage,
  maxHeight,
  overlayMaxHeight,
  size,
  buttonWidth,
  extraOverlayWidth = 0,
  canClear,
  tagClassName,
  isLoadingMore,
  onScroll,
  onSelect,
  onDeleteTag,
  onClearSearchKeyword,
  onUpdateSearchKeyword,
  onClearSelect,
  renderButton,
  renderCustomDropdownContent,
}: IDropdownTagsProps<OptionType, SelectedType>) => {
  const { width, ref } = useResizeDetector();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const containerId = useMemo(() => {
    return `dropdown-tags-container-${uniqueId()}`;
  }, []);

  useEffect(() => {
    const basetarget = document.querySelector(`.${containerId}`);

    window.addEventListener('click', (e) => {
      if (basetarget && dropdownRef.current) {
        const withinBoundariesBase = e.composedPath().includes(basetarget);
        const withinBoundariesDropdown = e.composedPath().includes(dropdownRef.current);
        if (withinBoundariesBase || withinBoundariesDropdown) {
          return;
        } else {
          setShowPopup(false);
        }
      }
    });

    return () => {
      window.removeEventListener('click', () => false);
    };
  }, [dropdownRef]);

  const isChecked = useCallback(
    (option: OptionType) => {
      const checked = selectedTag.some((tag) => tag.id === option.id);
      return checked;
    },
    [selectedTag],
  );

  const _renderDropdownContent = () => {
    if (fullMessage) {
      return <EmptyWord>{fullMessage}</EmptyWord>;
    }
    if (options.length === 0) {
      return <EmptyWord>{emptyWord}</EmptyWord>;
    }

    if (renderCustomDropdownContent) {
      return (
        <>
          {renderCustomDropdownContent(options)}
          {isLoadingMore && (
            <EmptyWord>
              <LoadingCircle />
            </EmptyWord>
          )}
        </>
      );
    }

    return options.map((option) => {
      return (
        <DropdownItem
          key={option.id}
          onClick={() => {
            //call external function
            onSelect(option);
          }}
        >
          <DropdownItemLabel>{option.name}</DropdownItemLabel>
          <Checkbox checked={isChecked(option) ? true : false} />
        </DropdownItem>
      );
    });
  };

  const _renderBtn = () => {
    if (renderButton) {
      return renderButton(selectedTag);
    }
    if (!isEmpty(selectedTag)) {
      return selectedTag.map((tag) => {
        return (
          <div key={tag.id} className="max-w-full !text-[16px]" title={tag.name}>
            <TagWithRemove
              className={tagClassName}
              label={tag.name}
              handleRemoveTag={() => onDeleteTag(tag.id)}
            />
          </div>
        );
      });
    }
    return (
      <div className="flex h-[0px] flex-nowrap items-center justify-between w-full">
        <PlaceholderText>{placeholder}</PlaceholderText>
        <FontAwesomeIcon icon={faChevronDown} color="rgba(0,0,0,.25)" />
      </div>
    );
  };

  return (
    <>
      {isDisabled ? (
        <DisabledContainer>
          <PlaceholderText>{placeholder}</PlaceholderText>
        </DisabledContainer>
      ) : (
        <Dropdown
          visible={showPopup}
          trigger={['click']}
          overlay={
            <DropdownContainer
              ref={dropdownRef}
              style={{
                width: width ? width + extraOverlayWidth : 0,
              }}
            >
              <SearchBox showBorder={!!canClear}>
                <SearchInput
                  onChange={(e) => onUpdateSearchKeyword(e.target.value)}
                  value={keyword}
                  onClear={onClearSearchKeyword}
                />
                {canClear && (
                  <ClearButton onClick={onClearSelect}>
                    <Font12G4W400> Clear selection</Font12G4W400>
                  </ClearButton>
                )}
              </SearchBox>
              <Content
                maxHeight={overlayMaxHeight}
                onScroll={onScroll}
                data-wrapper="amity-popover-wrapper"
              >
                {_renderDropdownContent()}
              </Content>
            </DropdownContainer>
          }
        >
          <div
            className={containerId}
            ref={ref}
            onClick={() => setShowPopup(true)}
            data-testid="test-dropdown-tags"
          >
            <Container
              size={size}
              style={{
                maxHeight: maxHeight ? maxHeight : 'auto',
                width: buttonWidth ? `${buttonWidth}px` : 'auto',
                justifyContent: isEmpty(selectedTag) ? 'space-between' : 'flex-start',
              }}
            >
              {_renderBtn()}
            </Container>
          </div>
        </Dropdown>
      )}
    </>
  );
};
