type IException = {
  [key: string]: string;
};

export const EXCEPTIONS_MAP: IException = {
  'User does not exist.': 'The username and/or password are incorrect. Please try again.',
  'Incorrect username or password.':
    'The username and/or password are incorrect. Please try again.',
  'Password does not conform to policy: Password must have uppercase characters':
    'Password does not conform to policy: Password must have uppercase characters',
  'Invalid verification code provided, please try again.':
    'Invalid verification code provided, please try again.',
  'Password reset required for the user': 'Password reset required for the user',
  'Attempt limit exceeded, please try after some time.':
    'Attempt limit exceeded, please try after some time.',
  'User has reach the password change limit': 'User has reach the password change limit',
};

export const EXCEPTIONS_PRE_AUTH_PREFIX = 'PreAuthentication failed with error';
