import React from 'react';
import { GhostButton, PrimaryButton } from '@amityco/diana-bot';
import {
  Font14G1W400,
  Font14G5W400,
  Font16G1W600,
  Font20G1W600,
  Frame,
} from '@components/UtilitiesComponent';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropDownProps } from 'antd';
import {
  Container,
  DateBox,
  DatePickerOverlay,
  DatePickerTopSection,
  InputWrap,
  SelectorContainer,
  StartDateTopBar,
  DateWidgeContent,
  EndDateTopBar,
  SelectMonthContainer,
  DateWidget,
} from './style';
import { EStep, useDateRangePicker } from './useDateRangePicker';
import { Calendar } from './Calendar';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

export type ISubmitCustomDatePayload = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
};

export type IDateRangePickerProps = {
  visible: boolean;
  onSubmit: ({
    startDate,
    endDate,
    startTime,
    endTime,
  }: ISubmitCustomDatePayload) => void;
  isClearEvent?: boolean;
  children?: React.ReactNode;
  isDisabledFutureDate?: boolean;
  disablePrevDate?: dayjs.Dayjs;
  disableTime?: boolean;
  customDisableFutureDates?: dayjs.Dayjs | undefined;
} & Omit<DropDownProps, 'overlay'>;

export const DateRangePicker = ({
  children,
  visible,
  isClearEvent,
  isDisabledFutureDate = true,
  disablePrevDate,
  disableTime = false,
  customDisableFutureDates,
  onSubmit,
  ...rest
}: IDateRangePickerProps) => {
  const {
    step,
    hoverDate,
    selectedDate,
    startDate,
    endDate,
    startDateArr,
    endDateArr,
    onClickDateBox,
    onSelectDate,
    onSelectTime,
    onClickNext,
    onClickPrev,
    onClear,
    onHoverDate,
  } = useDateRangePicker(isClearEvent);

  const _renderDateWidget = (type: 'start' | 'end') => {
    // left calendar
    if (type === 'start') {
      return (
        <DateWidget>
          <StartDateTopBar>
            {/* prev year */}
            <Frame centered width={28} height={28} onClick={() => onClickPrev('year')}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} fontSize={20} />
            </Frame>
            {/* prev month */}
            <Frame centered width={28} height={28} onClick={() => onClickPrev('month')}>
              <FontAwesomeIcon icon={faAngleLeft} fontSize={20} />
            </Frame>
            <SelectMonthContainer>
              <Frame centered width={144} height={28}>
                <Font16G1W600>{startDate.format('MMM YYYY')}</Font16G1W600>
              </Frame>
            </SelectMonthContainer>
          </StartDateTopBar>
          <Calendar
            isStartDate
            dateArr={startDateArr}
            customDisableFutureDates={customDisableFutureDates}
            onSelectDate={(date) =>
              onSelectDate(date, step === EStep.START ? 'start' : 'end')
            }
            onSelectTime={(dateTime) => onSelectTime(dateTime, 'start')}
            timepicker={{
              label: 'Start time',
            }}
            disablePrevDate={disablePrevDate}
            startTime={selectedDate.startTime}
            endTime={selectedDate.endTime}
            selectedTime={selectedDate.startTime}
            disableTime={disableTime}
            isDisabledFutureDate={isDisabledFutureDate}
            hoverDate={hoverDate}
            onHoverDate={onHoverDate}
            startDate={selectedDate.startDate}
            endDate={selectedDate.endDate}
          />
        </DateWidget>
      );
    }

    // right calendar
    return (
      <DateWidget>
        <EndDateTopBar>
          <SelectMonthContainer>
            <Frame centered width={144} height={28}>
              <Font16G1W600>{endDate.format('MMM YYYY')}</Font16G1W600>
            </Frame>
          </SelectMonthContainer>
          {/* display if end date is before or same day with today*/}
          {!isDisabledFutureDate ||
          endDate.isSame(dayjs(), 'day') ||
          endDate.isBefore(dayjs(), 'day') ? (
            <>
              {/* next month */}
              <Frame centered width={28} height={28} onClick={() => onClickNext('month')}>
                <FontAwesomeIcon icon={faAngleRight} fontSize={20} />
              </Frame>
              {/* next year */}
              <Frame centered width={28} height={28} onClick={() => onClickNext('year')}>
                <FontAwesomeIcon icon={faAngleDoubleRight} fontSize={20} />
              </Frame>
            </>
          ) : null}
        </EndDateTopBar>
        {/* generate days */}
        <Calendar
          customDisableFutureDates={customDisableFutureDates}
          disableTime={disableTime}
          dateArr={endDateArr}
          disablePrevDate={disablePrevDate}
          onSelectDate={(date) =>
            onSelectDate(date, step === EStep.START ? 'start' : 'end')
          }
          onSelectTime={(dateTime) => onSelectTime(dateTime, 'end')}
          isDisabledFutureDate={isDisabledFutureDate}
          timepicker={{
            label: 'End time',
          }}
          startTime={selectedDate.startTime}
          endTime={selectedDate.endTime}
          selectedTime={selectedDate.endTime}
          hoverDate={hoverDate}
          onHoverDate={onHoverDate}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
        />
      </DateWidget>
    );
  };
  const _renderOverlay = () => {
    return (
      <div>
        <SelectorContainer>
          <Font16G1W600>Select date and time</Font16G1W600>
          <InputWrap>
            {/* if start date is not selected active this box */}
            <DateBox
              isSelected={step === EStep.START}
              onClick={() => onClickDateBox(EStep.START)}
            >
              <Frame width={20} height={20} centered>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  color={step === EStep.START ? '#04BE8C' : ''}
                />
              </Frame>
              {!selectedDate.startDate ? (
                <Font14G5W400>Start date</Font14G5W400>
              ) : (
                <Font14G1W400>
                  {selectedDate.startDate.format('DD MMM YYYY')}
                  {disableTime ? null : selectedDate.startTime?.format(', HH:mm')}
                </Font14G1W400>
              )}
            </DateBox>
            <span>to</span>
            {/* if start date is selected active this box */}
            <DateBox
              isSelected={step === EStep.END}
              onClick={() => onClickDateBox(EStep.END)}
            >
              <Frame width={20} height={20} centered>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  color={step === EStep.END ? '#04BE8C' : ''}
                />
              </Frame>

              {!selectedDate.endDate ? (
                <Font14G5W400>End date</Font14G5W400>
              ) : (
                <Font14G1W400>
                  {selectedDate.endDate.format('DD MMM YYYY')}
                  {disableTime ? null : selectedDate.endTime?.format(', HH:mm')}
                </Font14G1W400>
              )}
            </DateBox>
            <GhostButton
              onClick={onClear}
              disabled={isNil(selectedDate.startDate) && isNil(selectedDate.endDate)}
            >
              Clear
            </GhostButton>
            <PrimaryButton
              // disable if user not select start date and end date
              disabled={!selectedDate.startDate || !selectedDate.endDate}
              onClick={() => {
                if (
                  selectedDate.startDate &&
                  selectedDate.endDate &&
                  selectedDate.startTime &&
                  selectedDate.endTime
                ) {
                  onSubmit({
                    startDate: selectedDate.startDate,
                    endDate: selectedDate.endDate,
                    startTime: selectedDate.startTime,
                    endTime: selectedDate.endTime,
                  });
                }
              }}
            >
              Apply
            </PrimaryButton>
          </InputWrap>
        </SelectorContainer>
        <DatePickerOverlay>
          <DatePickerTopSection>
            <Font20G1W600>Select date</Font20G1W600>
          </DatePickerTopSection>
          <DateWidgeContent>
            {_renderDateWidget('start')}
            {_renderDateWidget('end')}
          </DateWidgeContent>
        </DatePickerOverlay>
      </div>
    );
  };

  return (
    <Container>
      <Dropdown {...rest} visible={visible} overlay={_renderOverlay()}>
        {children}
      </Dropdown>
    </Container>
  );
};
