import { API_GLOBAL_TIME_OUT } from '@configs';
import axios from 'axios';

export const client = axios.create({
  withCredentials: true,
  timeout: API_GLOBAL_TIME_OUT,
});

export const CIM_API_ENDPOINT = import.meta.env.VITE_CIM_API_ENDPOINT;
export const CHATBOT_API_ENDPOINT = import.meta.env.VITE_CHATBOT_API_ENDPOINT;
export const CHATADS_API_ENDPOINT = import.meta.env.VITE_CHATADS_API_ENDPOINT;
export const CAMPAIGN_API_ENDPOINT = import.meta.env.VITE_CAMPAIGN_API_ENDPOINT;
export const INSIGHT_API_ENDPOINT = import.meta.env.VITE_INSIGHT_API_ENDPOINT;
export * from './entity';
export * from './template';
export * from './role';
export * from './intent';
export * from './campaign';
export * from './upload';
export * from './member';
export * from './user-profile';
export * from './richmenu';
export * from './richmenu_management';
export * from './audience';
export * from './richmenu_management';
export * from './mapper';
export * from './segment';
export * from './channel';
export * from './flow';
export * from './report';
export * from './dashboard';
export * from './bot_setting';
export * from './bot_variable';
export * from './sso';
