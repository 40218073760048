import React from 'react';
import { Select } from '@amityco/diana-bot';
import { DATE_INTERVAL, EDateInterval, FILTER_INTERVAL } from '@configs';
import { faCalendarDay, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnDivider,
  Container,
  CustomButton,
  LeftGroup,
  NormalBtn,
  RightGroup,
  SelectedCustomDateWrap,
  SelectedDateIntervalWrap,
} from './style';
import { DateRangePicker } from '@components/DateRangePicker';
import { Font14G3W400, Font14G5W400, Frame } from '@components/UtilitiesComponent';
import { useDateRangePickerFilter } from '../../../components/DateRangePicker/useDateRangePickerFilter';
import { useOverviewContext } from '../providers/OverviewProvider';
import { DropdownChannel } from '../DropdownChannel';

export const OverviewFilter = React.memo(() => {
  const {
    filter,
    onChangeCustomDate,
    onChangeFilterInterval,
    onChangeDateInterval,
    onSelectChannel,
    onClearChannel,
  } = useOverviewContext();
  const { clearDatePicker, openDatePickers, onToggleDatePickers, onClearDatePicker } =
    useDateRangePickerFilter();

  return (
    <Container>
      <LeftGroup>
        <DropdownChannel
          channels={filter.channels}
          onSelectChannel={onSelectChannel}
          onClearChannel={onClearChannel}
        />
        <Select
          options={FILTER_INTERVAL}
          value={filter.filterInterval}
          suffixIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 16 }} />}
          onChange={onChangeFilterInterval}
        />
      </LeftGroup>
      <RightGroup>
        {DATE_INTERVAL.map((item) => {
          if (item.value === EDateInterval.CUSTOM) {
            return (
              <React.Fragment key={item.value}>
                <DateRangePicker
                  visible={openDatePickers}
                  trigger={['click']}
                  onVisibleChange={(visible) => {
                    if (!visible) {
                      if (filter.dateInterval.value !== EDateInterval.CUSTOM) {
                        onClearDatePicker(false);
                        setTimeout(() => {
                          onClearDatePicker(true);
                        }, 0);
                      }

                      onToggleDatePickers();
                    }
                  }}
                  isClearEvent={clearDatePicker}
                  onSubmit={(payload) => {
                    // close date pickers after submit
                    onToggleDatePickers();
                    onChangeCustomDate(payload);
                  }}
                >
                  {filter.dateInterval.value === item.value ? (
                    <SelectedCustomDateWrap onClick={onToggleDatePickers}>
                      <Frame width={16} height={16} centered>
                        <FontAwesomeIcon icon={faCalendarDay} />
                      </Frame>
                      <div>
                        <Font14G3W400>
                          {item.label} : {filter.startDate.format('DD MMM YY, HH:mm')} -{' '}
                          {filter.endDate.format('DD MMM YY, HH:mm')}
                        </Font14G3W400>
                      </div>
                    </SelectedCustomDateWrap>
                  ) : (
                    <CustomButton
                      onClick={() => {
                        onToggleDatePickers();
                      }}
                    >
                      <Font14G5W400>Custom</Font14G5W400>
                    </CustomButton>
                  )}
                </DateRangePicker>
                <ColumnDivider />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={item.value}>
              {filter.dateInterval.value === item.value ? (
                <SelectedDateIntervalWrap>
                  <Frame width={16} height={16} centered>
                    <FontAwesomeIcon icon={faCalendarDay} />
                  </Frame>
                  <div>
                    <Font14G3W400>
                      {item.label} : {filter.startDate.format('DD MMM YY, HH:mm')} -{' '}
                      {filter.endDate.format('DD MMM YY, HH:mm')}
                    </Font14G3W400>
                  </div>
                </SelectedDateIntervalWrap>
              ) : (
                <NormalBtn
                  key={item.value}
                  onClick={() => {
                    onClearDatePicker(false);
                    setTimeout(() => {
                      onClearDatePicker(true);
                    }, 0);
                    onChangeDateInterval(item);
                  }}
                >
                  <Font14G5W400>{item.label}</Font14G5W400>
                </NormalBtn>
              )}
            </React.Fragment>
          );
        })}
      </RightGroup>
    </Container>
  );
});

OverviewFilter.displayName = 'OverviewFilter';
