import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoutes';
import { EChatbotSubProduct, EProduct, PATH } from '@configs';
import { AppLayout } from '@layouts';
import { useAuth, useMember } from '@providers';

import Login from '@pages/login';
import ChangePassword from '@pages/change_password';
import RequestResetPassword from '@pages/request_reset_password';
import ResetPassword from '@pages/reset_password';
import Error from '@pages/error';
import { LoadingCircle, LoadingWrap } from '@amityco/diana-bot';
import { hasAccess } from 'utils';
import RichmenuChannel from '@pages/richmenu_management/RichmenuChannel';
import Dashboard from '@pages/dashboard';
import Report from '@pages/report';
import { accessControl as accessControlFn } from 'utils';
import { isEmpty } from 'lodash';
import SSO from '@pages/sso';
const TemplateList = React.lazy(() => import('@pages/template/TemplateList'));
const TemplateEditor = React.lazy(() => import('@pages/template/TemplateEditor'));
const Entity = React.lazy(() => import('@pages/entity'));
const Intent = React.lazy(() => import('@pages/intent'));
// const ChatAdsList = React.lazy(() => import('@pages/campaign/chatads/ChatAdsList'));
// const ChatAdsEditor = React.lazy(() => import('@pages/campaign/chatads/ChatAdsEditor'));
const RichmenuEditor = React.lazy(() => import('@pages/richmenu/RichmenuEditor'));
const Richmenu = React.lazy(() => import('@pages/richmenu/Richmenu'));
const MemberList = React.lazy(() => import('@pages/cim/MemberList'));
const MemberEditor = React.lazy(() => import('@pages/cim/MemberEditor'));
const UserProfile = React.lazy(() => import('@pages/cim/UserProfile'));
const EditRichmenuChannel = React.lazy(
  () => import('@pages/richmenu_management/EditRichmenuChannel'),
);
const Mapper = React.lazy(() => import('@pages/mapper'));
const Audience = React.lazy(() => import('@pages/audience/Audience'));
const AudienceProfile = React.lazy(() => import('@pages/audience/AudienceProfile'));
const ChannelManagement = React.lazy(() => import('@pages/channel_management'));
const Flow = React.lazy(() => import('@pages/flow'));
const BotSetting = React.lazy(() => import('@pages/bot_setting'));
const BotVariable = React.lazy(() => import('@pages/bot_variable'));
const UserJourneyContent = React.lazy(
  () => import('@pages/dashboard/UserJourneyContent'),
);
const CampaignListContent = React.lazy(() => import('@pages/campaign/campaign_list'));
const CampaignEditor = React.lazy(() => import('@pages/campaign/campaign_editor'));
const CampaignStatus = React.lazy(() => import('@pages/campaign/campaign_status'));
// const ChatInsight = React.lazy(() => import('@pages/chat_insight'));
// const SegmentList = React.lazy(() => import('@pages/campaign/segment/SegmentList'));
const JourneyInsight = React.lazy(() => import('@pages/dashboard/JourneyInsight'));
const MainRoute: React.FC = () => {
  const { cognitoUser, cognitoAuthLoading } = useAuth();
  const { member, isFetchingMember } = useMember();
  const {
    canAccessAudience,
    canAccessRichmenu,
    canAccessChatlogic,
    canAccessInsight,
    canAccessInsightJourney,
  } = accessControlFn(member?.role);

  if (cognitoAuthLoading || isFetchingMember) {
    return (
      <LoadingWrap>
        <LoadingCircle />
      </LoadingWrap>
    );
  }

  const accessControl = {
    renderDashboard: () => {
      return (
        <Route
          path={PATH.DASHBOARD}
          element={
            <PrivateRoute authUser={cognitoUser}>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to={PATH.DASHBOARD_OVERVIEW} replace />} />
          <Route path={PATH.DASHBOARD_OVERVIEW} element={<Dashboard />} />
          <Route path={PATH.DASHBOARD_USER_JOURNEY} element={<UserJourneyContent />} />
        </Route>
      );
    },
    renderChatInsight: () => {
      if (!canAccessInsight()) return null;
      return (
        <Route
          path={PATH.CHAT_INSIGHT}
          element={
            <PrivateRoute authUser={cognitoUser}>
              <AppLayout />
            </PrivateRoute>
          }
        >
          {canAccessInsightJourney() && (
            <Route path={PATH.JOURNEY_INSIGHT} element={<JourneyInsight />} />
          )}
          {/* <Route path={PATH.CHAT_INSIGHT_ANALYZE} element={<ChatInsight />} /> */}
        </Route>
      );
    },
    renderReport: () => {
      return (
        <Route
          path={PATH.REPORT}
          element={
            <PrivateRoute authUser={cognitoUser}>
              <Report />
            </PrivateRoute>
          }
        />
      );
    },

    renderChatbotChatlogic: () => {
      if (canAccessChatlogic()) {
        return (
          <Route
            path={PATH.CHATBOT_CHATLOGIC}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Flow />} />
          </Route>
        );
      }
      return null;
    },
    renderChatbotContent: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CHATBOT)) {
        return (
          <Route
            path={PATH.CHATBOT_TEMPLATE}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<TemplateList />} />
            <Route path={PATH.CHATBOT_TEMPLATE_EDITOR} element={<TemplateEditor />} />
            <Route
              path={PATH.CHATBOT_TEMPLATE_EDITOR_WITH_ID}
              element={<TemplateEditor />}
            />
          </Route>
        );
      }
      return null;
    },
    renderEntity: () => {
      if (
        member?.role &&
        hasAccess(member?.role, EProduct.CHATBOT, EChatbotSubProduct.ENTITIES)
      ) {
        return (
          <Route
            path={PATH.CHATBOT_ENTITY}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Entity />} />
          </Route>
        );
      }
      return null;
    },
    renderIntent: () => {
      if (
        member?.role &&
        hasAccess(member?.role, EProduct.CHATBOT, EChatbotSubProduct.INTENTS)
      ) {
        return (
          <Route
            path={PATH.CHATBOT_INTENT}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Intent />} />
          </Route>
        );
      }
      return null;
    },
    renderBotVariable: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CHATBOT)) {
        return (
          <Route
            path={PATH.CHATBOT_VARIABLE}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<BotVariable />} />
          </Route>
        );
      }
      return null;
    },
    renderMapper: () => {
      if (
        member?.role &&
        hasAccess(member?.role, EProduct.CHATBOT, EChatbotSubProduct.MAPPER)
      ) {
        return (
          <Route
            path={PATH.CHATBOT_MAPPER}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Mapper />} />
          </Route>
        );
      }
      return null;
    },

    renderAudience: () => {
      if (canAccessAudience()) {
        return (
          <Route
            path={PATH.AUDIENCE}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Audience />} />
            <Route path={PATH.AUDIENCE_PROFILE} element={<AudienceProfile />} />
          </Route>
        );
      }
      return null;
    },

    renderRichmenu: () => {
      if (canAccessRichmenu()) {
        return (
          <Route
            path={PATH.RICHMENU}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Richmenu />} />
            <Route path={PATH.RICHMENU_EDITOR} element={<RichmenuEditor key="1" />} />
            <Route
              path={PATH.RICHMENU_EDITOR_WITH_ID}
              element={<RichmenuEditor key="2" />}
            />
            <Route path={PATH.RICHMENU_MANAGEMENT} element={<Richmenu />} />
            <Route
              path={PATH.RICHMENU_MANAGEMENT_WITH_ID}
              element={<RichmenuChannel />}
            />
            <Route
              path={PATH.RICHMENU_MANAGEMENT_EDITOR}
              element={<EditRichmenuChannel />}
            />
            <Route
              path={PATH.RICHMENU_MANAGEMENT_EDITOR_WITH_ID}
              element={<EditRichmenuChannel />}
            />
          </Route>
        );
      }
      return null;
    },
    renderCampaign: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CAMPAIGN)) {
        return (
          <Route
            path={PATH.CAMPAIGN}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Navigate to={`${PATH.BROADCAST}`} replace />} />
            <Route path={PATH.BROADCAST} element={<CampaignListContent />} />
            <Route path={PATH.BROADCAST_EDITOR} element={<CampaignEditor />} />
            <Route path={PATH.BROADCAST_EDITOR_WITH_ID} element={<CampaignEditor />} />
            <Route path={PATH.BROADCAST_STATUS} element={<CampaignStatus />} />
            {/* {hasAccess(member?.role, EProduct.CAMPAIGN, ECampaignSubProduct.SEGMENT) && (
              <Route path={PATH.SEGMENT} element={<SegmentList />} />
            )} */}
            {/* {hasAccess(member?.role, EProduct.CAMPAIGN, ECampaignSubProduct.CHATADS) && (
              <>
                <Route path={PATH.CHATADS} element={<ChatAdsList />} />
                <Route path={PATH.CHATADS_EDITOR} element={<ChatAdsEditor key="1" />} />
                <Route
                  path={PATH.CHATADS_EDITOR_WITH_ID}
                  element={<ChatAdsEditor key="2" />}
                />
              </>
            )} */}
          </Route>
        );
      }
      return null;
    },

    renderCimMember: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CIM)) {
        return (
          <Route
            path={PATH.MEMBERS}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<MemberList />} />
            <Route path={PATH.MEMBER_EDITOR} element={<MemberEditor />} />
          </Route>
        );
      }
      return null;
    },
    renderCimUserProfile: () => {
      return (
        <Route
          path={PATH.CIM_USER_PROFILE}
          element={
            <PrivateRoute authUser={cognitoUser}>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<UserProfile />} />
        </Route>
      );
    },
    renderChannelManagement: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CIM)) {
        return (
          <Route
            path={PATH.CHANNEL_MANAGEMENT}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<ChannelManagement />} />
          </Route>
        );
      }
      return null;
    },
    renderBotSetting: () => {
      if (member?.role && hasAccess(member?.role, EProduct.CIM)) {
        return (
          <Route
            path={PATH.BOT_SETTING}
            element={
              <PrivateRoute authUser={cognitoUser}>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<BotSetting />} />
          </Route>
        );
      }
    },

    renderDefaultRedirect: () => {
      if (member && !isEmpty(member)) {
        const canAccessChatbot = hasAccess(member.role, EProduct.CHATBOT);
        const canAccessCampaign = hasAccess(member.role, EProduct.CAMPAIGN);
        const canAccessLivechat = hasAccess(member.role, EProduct.LIVECHAT);
        const canAccessGenai = hasAccess(member.role, EProduct.GENAI);
        const anAccessInsight = hasAccess(member.role, EProduct.INSIGHT);
        if (
          canAccessChatbot ||
          canAccessCampaign ||
          canAccessLivechat ||
          canAccessGenai ||
          anAccessInsight
        ) {
          return (
            <Route path="*" element={<Navigate to={PATH.DASHBOARD_OVERVIEW} replace />} />
          );
        }
      }

      return (
        <Route
          path="*"
          element={
            <PrivateRoute authUser={cognitoUser}>
              <Navigate to={PATH.ERROR} replace />
            </PrivateRoute>
          }
        />
      );
    },
  };

  return (
    <Routes>
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route path={PATH.SSO} element={<SSO />} />
      <Route path={PATH.CHANGE_PASSWORD} element={<ChangePassword />} />
      <Route path={PATH.REQUEST_RESET_PASSWORD} element={<RequestResetPassword />} />
      <Route path={PATH.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={PATH.ERROR} element={<Error />} />
      {accessControl.renderDashboard()}
      {accessControl.renderReport()}
      {accessControl.renderChatbotChatlogic()}
      {accessControl.renderChatbotContent()}
      {accessControl.renderChannelManagement()}
      {accessControl.renderAudience()}
      {accessControl.renderBotVariable()}
      {accessControl.renderRichmenu()}
      {accessControl.renderEntity()}
      {accessControl.renderIntent()}
      {accessControl.renderMapper()}
      {accessControl.renderCampaign()}
      {accessControl.renderCimMember()}
      {accessControl.renderCimUserProfile()}
      {accessControl.renderBotSetting()}
      {accessControl.renderChatInsight()}
      {accessControl.renderDefaultRedirect()}
    </Routes>
  );
};

export default MainRoute;
