import React from 'react';
import { LoadingCircle } from '@amityco/diana-bot';
import { Font12G3W400 } from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import { Container, ErrorContainer, RetryButton } from './style';

export type ICardContentProps = {
  isLoading: boolean;
  isError: boolean;
  height?: string;
  onRetry: () => void;
  children?: React.ReactNode;
};

export const CardContent = ({
  isLoading,
  isError,
  children,
  height,
  onRetry,
}: ICardContentProps) => {
  const t = useTranslation();

  if (isError) {
    return (
      <ErrorContainer>
        <Font12G3W400>{t('dashboard.fetch.error.message')}</Font12G3W400>
        <Font12G3W400>
          <RetryButton onClick={onRetry}>
            {t('dashboard.fetch.error.retry.button')}
          </RetryButton>
        </Font12G3W400>
      </ErrorContainer>
    );
  }
  if (isLoading) {
    return (
      <Container height={height} className="flex justify-center items-center">
        <LoadingCircle />
      </Container>
    );
  }

  return <Container height={height}>{children}</Container>;
};
