import {
  CHATBOT_API_ENDPOINT,
  client,
  CIM_API_ENDPOINT,
  INSIGHT_API_ENDPOINT,
} from '@api';
import {
  IRequestReportParams,
  IReportResult,
  IOverviewGenReportParams,
  IOverviewGenReportResponse,
  IRequestUserJourneyReportParams,
  IInsightReportParams,
  IInsightReportResponse,
} from '@types';

export const exportReport = async (payload: IRequestReportParams) => {
  const { data }: { data: IReportResult } = await client.post(
    `${CHATBOT_API_ENDPOINT}/api/v1/reports/generate`,
    payload,
  );
  return data;
};

export const getReportStatus = async (id: string) => {
  const { data }: { data: IReportResult } = await client.get(
    `${CHATBOT_API_ENDPOINT}/api/v1/reports/${id}`,
  );
  return data;
};

export const getDashboardReportStatus = async (id: string) => {
  const { data }: { data: IReportResult } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/reports/${id}`,
  );
  return data;
};

export const getInsightReportStatus = async (id: string) => {
  const { data }: { data: IInsightReportResponse } = await client.get(
    `${INSIGHT_API_ENDPOINT}/api/v1/reports/${id}`,
  );
  return data;
};

export const exportDashboardOverviewReport = async (
  payload: IOverviewGenReportParams,
) => {
  const { data }: { data: IOverviewGenReportResponse } = await client.post(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/reports/generate`,
    payload,
  );
  return data;
};

export const exportInsightReport = async (payload: IInsightReportParams) => {
  const { data }: { data: IInsightReportResponse } = await client.post(
    `${INSIGHT_API_ENDPOINT}/api/v1/reports/generate`,
    payload,
  );
  return data;
};

export const exportUserJourneyReport = async (
  payload: IRequestUserJourneyReportParams,
) => {
  const { data }: { data: IReportResult } = await client.post(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/reports/generate`,
    payload,
  );
  return data;
};
