import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  max-width: 410px;
  height: 100vh;
  padding: 0 12px;
  margin: 0 auto;

  form {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
`;
